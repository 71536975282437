import React from 'react';
import styled from 'styled-components';

// Main styles for html markdown content
const ContentDiv = styled.div`
  max-width: ${(p) => p.theme.page.contentWidth};

  a {
    color: ${(p) => p.theme.color.tertiary.default};

    &:visited {
      color: ${(p) => p.theme.color.tertiary.default};
    }

    &:hover {
      color: ${(p) => p.theme.color.tertiary.light2};
    }
  }

  li {
    padding: 0.3rem 0;

    ::marker {
      font-weight: 500;
    }
  }
  ol {
    li {
      padding: 0.6rem 0;
    }
  }

  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    margin: 1.5rem 0 3.5rem 0;
    box-shadow: 0px 0px 6px 1px ${(p) => p.theme.color.gray.light7};
    border-radius: 5px;
    font-size: 14px;

    thead {
      tr {
        th {
          font-weight: 500;
          text-transform: uppercase;
          letter-spacing: 1px;
          padding: 1rem;

          text-align: left;
          border-bottom: 1px solid ${(p) => p.theme.color.gray.light8};
        }
      }
    }
    tbody {
      tr:nth-child(odd) {
        background: ${(p) => p.theme.color.gray.light8};
      }
    }
    th,
    td {
      line-height: 24px;
      padding: 0.5rem 1rem;
      text-align: left;
    }
  }
`;

const Content = ({ html }) => {
  return <ContentDiv dangerouslySetInnerHTML={{ __html: html }} />;
};

export default Content;
