import React from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import Layout from '../../components/Layout';
import Navigation from '../../components/Navigation';
import Html from '../../components/Html';
import CowaitLink from '../../components/CowaitLink';

/*
  Styled components Copied from ./doc-page. Remove when we 
  build a welcome screen from the /docs page.
  I don't think the /docs root page should be editable on github,
  that's why it's not using the template.
 */

const DocsWrapper = styled.main`
  padding-left: 1rem;
  padding-right: 2rem;
  padding-top: 8rem;
  max-width: ${(p) => p.theme.page.contentWidth};
  margin: auto;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.small}px) {
    max-width: ${(p) => p.theme.page.contentWidth};
    padding-right: 0;
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.medium}px) {
    max-width: 33rem;
    padding-left: ${(p) => p.theme.page.leftNavMaxWidth};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.large}px) {
    padding-left: ${(p) => p.theme.page.leftNavMaxWidth};
  }

  @media screen and (min-width: ${(p) => p.theme.breakpoints.xlarge}px) {
    padding-left: ${(p) => p.theme.page.leftNavMaxWidth};
    padding-right: 0;
    max-width: calc(${(p) => p.theme.page.contentMaxWidth});
  }
`;

const Title = styled.h1`
  max-width: ${(p) => p.theme.page.contentMaxWidth};
  margin: 2rem auto;
`;

const DocsFooter = styled.div`
  max-width: ${(p) => p.theme.page.contentWidth};
  margin-top: 8rem;
  margin-bottom: 10rem;

  p {
    font-size: 0.85rem;
    color: ${(p) => p.theme.color.gray.default};
  }

  span {
    font-size: 0.9rem;
  }
`;

const StyledLink = styled(Link)`
  color: ${(p) => p.theme.color.tertiary.default};

  &:visited {
    color: ${(p) => p.theme.color.tertiary.default};
  }
  &:hover {
    color: ${(p) => p.theme.color.tertiary.light2};
  }

  &.is-current-link {
    color: ${(p) => p.theme.color.tertiary.default};
  }
`;

const DocsIndexTemplate = (props) => {
  const { data, location } = props;
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout location={location} seo={{}}>
      <div>
        <Navigation.Landing.Topbar isSubPage />
        <Navigation.Docs.Navbar />
        <Navigation.Docs.Mobile />

        <DocsWrapper>
          <Title> {frontmatter.title} </Title>
          <Html.Wrapper>
            <p>
              {' '}
              Welcome to the Cowait Documentation. To get started, take a look at the
              <StyledLink to={'/docs/quick-start'}> Quick Start </StyledLink>.
            </p>
          </Html.Wrapper>
          <DocsFooter>
            <p>The cowait documentation is under development, you can help create it at:</p>
            <CowaitLink.EditOnGithub
              to={'https://github.com/backtick-se/cowait/tree/master/docs'}
              title="Contribute on Github"
            />
          </DocsFooter>
        </DocsWrapper>
      </div>
    </Layout>
  );
};

export default DocsIndexTemplate;

export const docsPageQuery = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
      }
    }
  }
`;
