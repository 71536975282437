import styled from 'styled-components';

const Wrapper = styled.main`
  display: flex;
  flex-direction: column-reverse;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.xlarge}px) {
    flex-direction: row;
    max-width: ${(p) => p.theme.page.contentMaxWidth};
    margin: auto;
  }
`;

export default Wrapper;
