import React from 'react';
import styled from 'styled-components';
import { AiOutlineAlignLeft } from 'react-icons/ai';

const TocWrapper = styled.div`
  padding-bottom: 1.52rem;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.xlarge}px) {
    max-width: ${(p) => p.theme.page.tableOfContentsMaxWidth};
    position: sticky;
    top: 4rem;
    align-self: flex-start;
    padding-left: 3rem;
    padding-bottom: 0;
  }
  ul {
    padding: 0;
    list-style: none;
    line-height: 2rem;
    font-size: 0.857rem;

    li {
      color: ${(p) => p.theme.color.gray.default};
      a:hover {
        color: ${(p) => p.theme.color.tertiary.default};
      }
    }
  }
`;

const Title = styled.h2`
  color: ${(p) => p.theme.color.gray.default};
  font-size: 0.9rem;
  letter-spacing: 0.075em;
  text-transform: uppercase;
`;

const TableOfContents = ({ html }) => {
  return (
    <TocWrapper>
      <Title>
        <AiOutlineAlignLeft style={{ paddingRight: '0.75rem' }} />
        Contents
      </Title>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </TocWrapper>
  );
};

export default TableOfContents;
